import Image from 'next/image';
import { StyledContentBanner } from './styled';

const BannerCards = () => (
  <StyledContentBanner>
    <div className="img-col">
      <div className="img-wrapper">
        <Image
          src="/static/images/drawings/create_custom.png"
          alt="Create custom widgets with Pollme"
          layout="fill"
        />
      </div>
    </div>
    <div className="content-col">
      <h2>Create fully customizable poll and form widgets</h2>
      <p>
        If you need quick poll responses, micro-surveys, email signups, a poll on your remote
        meetings, feedback on your website, etc., <strong>we have you covered!</strong> Our
        customizable widgets are ready to use. <br /> You can share them and integrate them on any
        website.
      </p>
    </div>
  </StyledContentBanner>
);
export default BannerCards;
