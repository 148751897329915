/* eslint-disable camelcase */
import Link from 'next/link';

import { StyledBigCTA } from '@/styles/index';
import { Icon } from 'react-icons-kit';
// import { userPlus } from 'react-icons-kit/fa/userPlus';
// import { userO } from 'react-icons-kit/fa/userO';
import { ic_add } from 'react-icons-kit/md/ic_add';

const CTABig = () => (
  <StyledBigCTA>
    <div className="cta-heading">Your first poll with just a few clicks</div>
    <div className="cta-lead">
      Use Pollme for free.
      <br />
      Get started now
    </div>
    <div className="cta-actions">
      <Link href="/create">
        <a>
          <button type="button" className="btn-orange">
            <Icon size={28} icon={ic_add} /> Create a new Poll
          </button>
        </a>
      </Link>
    </div>
  </StyledBigCTA>
);

export default CTABig;
