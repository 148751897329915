import Image from 'next/image';
import { StyledContentBanner } from './styled';

const BannerCards = () => (
  <StyledContentBanner>
    <div className="content-col">
      <h2>Complete No-Code solution</h2>
      <p>
        Create new polls, share them via a link, email, text, <em>shouting</em>... however you want!
        <br />
        Use them on your meetings to share real-time responses, integrate them on your website, etc.
        Configure robust solutions with just a few clicks.
      </p>
    </div>
    <div className="img-col">
      <div className="img-wrapper">
        <Image
          src="/static/images/drawings/simple_solution.png"
          alt="Create your polls, no code required"
          layout="fill"
        />
      </div>
    </div>
  </StyledContentBanner>
);
export default BannerCards;
