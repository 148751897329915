import styled from 'styled-components';

export const StyledLandingBanner = styled.div`
  padding: 0 4rem;
  display: grid;
  grid-template-columns: 45% auto;
  align-items: center;
  min-height: calc(100vh - 80px); /** remove 80px from nav */
  position: relative;
  overflow: hidden;
  &::before {
    position: absolute;
    content: '';
    ${'' /* background-color: #f1f1f1; */}
    top: 0;
    right: 0;
    width: 80%;
    bottom: 0;
    margin-top: -5%;
    margin-right: -15%;
    z-index: -1;
    ${
      '' /* background-image: radial-gradient(
        circle,
        var(--yellow) 0%,
        var(--yellow) 5%,
        #fff1bc 5%,
        #fff1bc 20%,
        var(--yellow) 20%,
        var(--yellow) 35%,
        #fff1bc 35%,
        #fff1bc 50%,
        var(--yellow) 50%,
        var(--yellow) 65%,
        var(--white) 65%,
        var(--white) 80%,
        var(--white) 80%,
        var(--white) 100%
      ); */
    }
    background-image: radial-gradient(
        circle,
        var(--yellow) 0%,
        var(--yellow) 5%,
        var(--warning-background) 5%,
        var(--warning-background) 20%,
        var(--yellow) 20%,
        var(--yellow) 35%,
        var(--warning-background) 35%,
        var(--warning-background) 50%,
        var(--yellow) 50%,
        var(--yellow) 65%,
        var(--white) 65%,
        var(--white) 80%,
        var(--white) 80%,
        var(--white) 100%
      );
  }
  .gradient-primary {
    background-color: var(--blue);
    background-image: linear-gradient(45deg, var(--blue), var(--indigo));
  }
  .gradient-text {
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
  .content-col {
    h1 {
      font-size: 6.5rem;
      line-height: 6rem;
      margin: 0;
      padding-bottom: 2rem;
      font-weight: 800;
      letter-spacing: 2px;
      /* Fallback: Set a background color. */
      background-color: var(--blue);
      background-image: linear-gradient(45deg, var(--blue), var(--indigo));
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
    }
    .lead {
      font-size: 1.5rem;
      line-height: 2rem;
      ${'' /* color: var(--muted-strong); */}
      opacity: 0.9;
      font-weight: 500;
      padding-bottom: 0.5rem;
    }
  }
  .hero-col {
    width: 100%;
    height: 100%;
    padding: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    ${'' /* z-index: -1; */}
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.xxl}) {
    ${'' /* grid-template-columns: 50% auto; */}
    .content-col {
      h1 {
        font-size: 5.5rem;
        line-height: 5rem;
      }
      .lead {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.xl}) {
    grid-template-columns: 1fr;
    &::before {
      top: 25%;
      right: 0;
      width: 100%;
      bottom: 0;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: -25%;
    }
    .content-col {
      padding-top: 4rem;
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    padding: 0 3rem;
    &::before {
      top: 25%;
      right: 0;
      width: 100%;
      bottom: 0;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: -25%;
    }
    .content-col {
      h1 {
        font-size: 5rem;
        line-height: 4rem;
      }
    }
    .hero-col {
      padding: 2rem 0;
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    padding: 0 2rem;
    .content-col {
      h1 {
        font-size: 4rem;
        line-height: 3.5rem;
        text-align: center;
      }
      .lead {
        font-size: 1.5rem;
        line-height: 2rem;
        text-align: center;
      }
    }
  }
`;

export const StyledContentBanner = styled.div`
  padding: 6rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  align-items: center;
  position: relative;
  .content-col {
    padding: 0 4rem;
    h2 {
      font-size: 2.5rem;
      margin: 0;
    }
    p,
    ul,
    ol {
      font-size: 1.175rem;
      line-height: 2rem;
      word-break: break-word;
    }
  }
  .img-col {
    display: flex;
    justify-content: center;
    align-items: center;
    .img-wrapper {
      width: 300px;
      height: 300px;
      position: relative;
      img {
        object-fit: contain;
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.lg}) {
    ${'' /* padding: 0 3rem; */}
    grid-template-columns: 1fr;
    .content-col {
      padding: 0 2rem;
      padding-bottom: 1rem;
    }
    .img-col {
      .img-wrapper {
        width: 250px;
        height: 250px;
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    .img-col {
      .img-wrapper {
        width: 250px;
        height: 250px;
      }
    }
  }
`;

export const StyledProductBanner = styled.div`
  padding: 4rem 0;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    bottom: 4rem;
    right: 1rem;
    width: 100%;
    height: 100%;
    background-image: url(/static/images/drawings/alien_back.png);
    z-index: -5;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 500px;
    opacity: 0.7;
  }
  &::after {
    position: absolute;
    content: '';
    bottom: -2rem;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/static/images/drawings/city_back.png);
    z-index: -5;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 650px;
    opacity: 0.7;
  }
  h2 {
    text-align: center;
    font-size: 3.5rem;
    font-weight: 600;
    padding-bottom: 2rem;
    /* Fallback: Set a background color. */
    background-color: var(--blue);
    background-image: linear-gradient(45deg, var(--blue), var(--indigo));
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
  h3 {
    margin: 0;
    color: var(--white);
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 2.25rem;
    letter-spacing: 1.5px;
    padding-bottom: 0.5rem;
  }
  p {
    color: var(--white);
    font-size: 1rem;
    line-height: 1.5rem;
    word-break: break-word;
  }
  .row-container {
    padding: 0 4rem;
    padding-top: 8rem;
    padding-bottom: 4rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    .col-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2rem;
      .feature-card {
        padding: 1.875rem 2rem;
        border-radius: 1rem;
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: 1fr 2fr 100px;
        position: relative;
        min-height: 325px;
        &::before {
          position: absolute;
          content: '';
          background-color: var(--indigo);
          background-image: linear-gradient(45deg, var(--blue), var(--indigo));
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 1rem;
          z-index: -2;
        }
        &.pink-yellow {
          &::before {
            background-color: var(--yellow);
            background-image: linear-gradient(45deg, var(--pink-hover), var(--yellow));
          }
        }
        &.pink-red {
          &::before {
            background-color: var(--pink);
            background-image: linear-gradient(45deg, var(--pink), var(--red));
          }
        }
        &.teal-green {
          &::before {
            background-color: var(--green);
            background-image: linear-gradient(45deg, var(--teal), var(--green));
          }
        }
        &.muted {
          &::before {
            background-color: var(--muted-strong);
            background-image: linear-gradient(45deg, var(--muted-strong), var(--muted-strong));
          }
        }
        &.orange {
          &::before {
            background-color: var(--orange);
            background-image: linear-gradient(45deg, var(--orange), var(--orange-hover));
          }
        }
      }
      &.first {
        .feature-card:nth-child(1) {
          margin-top: -6rem;
          margin-bottom: 6rem;
        }
        .feature-card:nth-child(3) {
          margin-bottom: -6rem;
          margin-top: 6rem;
        }
      }
      &.second {
        .feature-card:nth-child(1) {
          margin-top: -6rem;
          margin-bottom: 6rem;
        }
        .feature-card:nth-child(3) {
          margin-bottom: -6rem;
          margin-top: 6rem;
        }
      }
    }
  }
  .drawing-wrapper {
    width: 100%;
    height: 225px;
    position: relative;
    z-index: -1;
    margin-top: -3rem;
    &.mt-6 {
      margin-top: -6rem;
    }
    &.mt-5 {
      margin-top: -5rem;
    }
    &.mt-4 {
      margin-top: -4rem;
    }
    &.mt-3 {
      margin-top: -3rem;
    }
    &.mt-2 {
      margin-top: -2rem;
    }
    &.mt-1 {
      margin-top: -1rem;
    }
    img {
      object-fit: contain;
      object-position: center;
    }
    &.left {
      margin-left: -5rem;
      img {
        object-position: left;
      }
    }
    &.right {
      margin-left: 5rem;
      img {
        object-position: right;
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.lg}) {
    .row-container {
      padding-top: 4rem;
      padding-bottom: 4rem;
      .col-container {
        grid-template-columns: 1fr;
        &.first,
        &.second {
          .feature-card:nth-child(1),
          .feature-card:nth-child(3) {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
        &.third {
          grid-gap: 0;
        }
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    &::before {
      background-size: 400px;
    }
    &::after {
      background-size: 500px;
    }
    h2 {
      padding: 0;
    }
    .row-container {
      padding: 3rem 3rem;
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    .row-container {
      padding: 3rem 2rem;
    }
  }
`;
