// import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import intlMessages from '@/i18n/index';

export function useIntlMessage() {
  const intl = useIntl();
  // console.log({ intl });
  // useEffect(() => {
  //   if (!intl) {
  //     intl = useIntl();
  //   }
  // }, []);
  const defaultMessages = intlMessages[intl.defaultLocale];
  const intlMessage = ({ id, route }) => {
    // console.log({ id, route });
    const parsedId = route ? `${route}.${id}` : id;
    // console.log({ parsedId });
    // console.log({ defaultMessages, default: defaultMessages[parsedId] });

    return intl.formatMessage({
      id: parsedId,
      defaultMessage: defaultMessages[parsedId]
    });
  };

  return { ...intl, defaultMessages, intlMessage };
}

export default useIntlMessage;
