import Image from 'next/image';
import { StyledProductBanner } from './styled';

const BannerProduct = () => (
  <StyledProductBanner>
    <h2>Product details</h2>
    <div className="row-container">
      <div className="col-container first">
        <div className="feature-card orange">
          <h3>Create polls instantly</h3>
          <p>Simply share a link or QR code to collect responses.</p>
          <div className="drawing-wrapper mt-5">
            <Image
              src="/static/images/drawings/create_polls.png"
              alt="pollme create polls"
              layout="fill"
            />
          </div>
        </div>
        <div className="feature-card pink-yellow">
          <h3>Mobile ready</h3>
          <p>All your polls are optimized for mobile performance and styling from the get go!</p>
          <div className="drawing-wrapper right mt-5">
            <Image
              src="/static/images/drawings/mobile_rule.png"
              alt="pollme mobile ready"
              layout="fill"
            />
          </div>
        </div>
        <div className="feature-card pink-red">
          <h3>Live polls</h3>
          <p>Use presentation mode to start a live poll and capture results in real-time.</p>
          <div className="drawing-wrapper right mt-5">
            <Image
              src="/static/images/drawings/attention_live.png"
              alt="pollme live polls"
              layout="fill"
            />
          </div>
        </div>
      </div>
      <div className="col-container second">
        <div className="feature-card teal-green">
          <h3>Simple text-based share</h3>
          <p>
            Easily share your polls and simple micro-surveys on iMessage, Whatsapp, Telegram, etc.
            Gather responses quick and easy!
          </p>
          <div className="drawing-wrapper mt-4">
            <Image
              src="/static/images/drawings/text_share.png"
              alt="pollme advanced logic"
              layout="fill"
            />
          </div>
        </div>
        <div className="feature-card muted">
          <h3>Embeddable Widgets</h3>
          <p>Integrate your polls and micro-surveys on your websites why a simple copy-paste.</p>
          <div className="drawing-wrapper mt-4 right">
            <Image
              src="/static/images/drawings/ideas_widget.png"
              alt="pollme Embeddable widgets"
              layout="fill"
            />
          </div>
        </div>
        <div />
      </div>
      <div className="col-container third">
        <div />
        <div className="feature-card">
          <h3>Advanced logic types</h3>
          <p>Build polls and micro-surveys with conditional flow (Soon!).</p>
          <div className="drawing-wrapper right">
            <Image
              src="/static/images/drawings/logic_robot.png"
              alt="pollme advanced logic"
              layout="fill"
            />
          </div>
        </div>
        <div />
      </div>
    </div>
  </StyledProductBanner>
);
export default BannerProduct;
