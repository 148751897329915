import React from 'react';
import { ViewWrapper, SinglePageWrapper, ContentWrapper } from '@/styles/index';
// import Navbar from '@/components/Layout/Navbar';
// import Footer from '@/components/Layout/Footer';
import CTABig from '@/components/Layout/CTABig';
import LandingBanner from '@/components/Landing';
import BannerCards from '@/components/Landing/BannerCards';
import BannerCardLeft from '@/components/Landing/BannerCardLeft';
import BannerProduct from '@/components/Landing/BannerProduct';
import HeadMeta from '@/components/HeadMeta';
import { useSession } from 'next-auth/client';
import dynamic from 'next/dynamic';

const Footer = dynamic(() => import('@/components/Layout/Footer'));
const Navbar = dynamic(() => import('@/components/Layout/Navbar'));

const Landing = () => {
  const [session] = useSession();
  return (
    <ViewWrapper>
      <HeadMeta />
      <Navbar session={session} />
      <SinglePageWrapper>
        <LandingBanner />
        <ContentWrapper>
          <BannerCardLeft />
          <BannerCards />
          <BannerProduct />
        </ContentWrapper>
      </SinglePageWrapper>
      <CTABig />
      <Footer />
    </ViewWrapper>
  );
};
export default Landing;
