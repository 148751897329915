/* eslint-disable react/no-danger */
import Head from 'next/head';
import { useRouter } from 'next/router';
import config from '../siteConfig';

const HeadMeta = props => {
  const { title, description, children, ...customMeta } = props;
  const router = useRouter();

  const meta = {
    title: title || config.siteTitle,
    description: description || config.siteDescription,
    image: config.siteSquareBanner,
    type: 'website',
    ...customMeta
  };

  const url = router ? `${config.siteUrl}${router.asPath}` : `${config.siteUrl}`;

  const organizationSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: config.siteUrl,
    logo: config.siteFav,
    email: config.userEmail,
    name: config.siteTitleAlt
  };

  return (
    <Head>
      <title>{meta.title}</title>
      {meta.robots && <meta name="robots" content={meta.robots} />}
      <meta name="description" content={meta.description} />
      <meta property="og:url" content={`${url}`} />
      <link rel="canonical" href={`${url}`} />
      <meta property="og:type" content={meta.type} />
      <meta property="og:site_name" content={config.siteTitleAlt} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:image" content={meta.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={config.userTwitter} />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.image} />
      {meta.date && <meta property="article:published_time" content={meta.date} />}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationSchema) }}
      />
      {children}
    </Head>
  );
};
export default HeadMeta;
