import LandingPollTemplate from '@/components/Templates/LandingTemplate';
import useIntlMessage from '@/hooks/use-intl-message';
import { StyledLandingBanner } from './styled';

const LandingBanner = () => {
  const intl = useIntlMessage();
  // console.log({ intl });
  const { intlMessage: m } = intl;
  return (
    <StyledLandingBanner>
      <div className="content-col">
        <h1>{m({ id: 'heading', route: 'pages.index' })}</h1>
        <div className="lead">
          The easiest way to create polls and to collect responses on the internet.
        </div>
        <div className="lead">
          If <strong>*you*</strong> don't suck, why should your polls?
        </div>
      </div>
      <div className="hero-col">
        <LandingPollTemplate />
      </div>
    </StyledLandingBanner>
  );
};

export default LandingBanner;
